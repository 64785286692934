import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Terms from './Terms';
import Privacy from './Privacy';
import './App.css';


const NotFound = () => {
  return (
      <div className="content">
          <h1>404 - Page not found</h1>
      </div>
  );
}
function App() {
    return (
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
    );
}

export default App;

